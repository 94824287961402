body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: black;
}
:root {
  --font-ex-large: 3rem;
  --font-large: 2rem;
  --font-medium: 1.5rem;
  --font-mid-medium: 1.15rem;
  --font-small: 0.85rem;
  --font-ex-small: 0.7rem;
  --font-bold: 500;
  --font-mid-bold: 600;
  --font-ex-bold: 700;
  --font-light: lighter;
  --font-primary: 'Merriweather', serif;
  --font-secondary: 'Montserrat', sans-serif;
  --font-tertiary: 'Josefin Sans', sans-serif;
  --color-primary: #153448;
  --color-secondary: #3c5b6f;
  --color-tertiary: #948979;
  --color-quaternary: #dfd0b8;
}
@media (max-width: 768px) {
  :root {
    --font-ex-large: 2.5rem;
    --font-large: 1.8rem;
    --font-medium: 1.3rem;
    --font-mid-medium: 1.05rem;
    --font-small: 0.75rem;
    --font-ex-small: 0.6rem;
  }
}
/* paper animation */
@keyframes movePaper {
  0% {
    transform: translateY(0) scale(0);
  }
  50% {
    transform: translateY(-300px) scale(0.5);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}
@keyframes movePaperBack {
  0% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-300px) scale(0.5);
  }
  100% {
    transform: translateY(0) scale(0);
  }
}

/* text slide animation */
@keyframes textSlideShow {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes textSlideHide {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-200px);
    opacity: 0;
  }
}
