@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-primary-tempTwo);
}

:root {
  --font-large-tempTwo: 0.8rem;
  --font-medium-tempTwo: 0.45rem;
  --font-small-tempTwo: 0.35rem;
  --font-mid-small-tempTwo: 0.25rem;
  --font-mid-bold-tempTwo: 500;
  --font-bold-tempTwo: 600;
  --font-ex-bold-tempTwo: 700;
  --color-primary-tempTwo: #00807e;
  --color-secondary-tempTwo: #f5f5f5;
  --font-primary-tempTwo: 'Titillium Web', sans-serif;
}

/* utilities */
.font-large-tempTwo {
  font-size: var(--font-large-tempTwo);
}

.font-medium-tempTwo {
  font-size: var(--font-medium-tempTwo);
}

.font-small-tempTwo {
  font-size: var(--font-small-tempTwo);
}

.font-mid-small-tempTwo {
  font-size: var(--font-mid-small-tempTwo);
}

.font-ex-small-tempTwo {
  font-size: var(--font-ex-small-tempTwo);
}

.font-bold-tempTwo {
  font-weight: var(--font-bold-tempTwo);
}

.font-light-tempTwo {
  font-weight: var(--font-light-tempTwo);
}

.color-primary-tempTwo {
  color: var(--color-primary-tempTwo);
}

.color-secondary-tempTwo {
  color: var(--color-secondary-tempTwo);
}

.cont-tempTwo {
  display: flex;
  flex-direction: column;
}

.box-tempTwo {
  display: flex;
  flex-direction: column;
  padding: 1px 0;
}

.box-item-tempTwo {
  padding: 0;
  font-size: var(--font-medium-tempTwo);
}

/* named like this as this div comes at the top of a box */
.box-top-tempTwo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading-tempTwo {
  color: var(--color-primary-tempTwo);
  font-family: serif;
}

.line-div-tempTwo {
  height: 1px;
  background-color: var(--color-primary-tempTwo);
}
.main-container-tempTwo {
  color: black;
  background-color: white;
  font-size: 8px;
  min-width: 25vw;
}
.name-tempTwo {
  margin: 10px 10px 5px;
  color: var(--color-primary-tempTwo);
  font-size: var(--font-large-tempTwo);
}

.big-line-tempTwo {
  height: 5px;
  background-color: var(--color-primary-tempTwo);
  /* margin: 10px 0; */
}

.details-section-tempTwo {
  display: flex;
  margin: 5px 10px;
  gap: 10px;
}

.left-section-tempTwo {
  display: flex;
  flex-direction: column;
  min-width: 17vw;
  @media (max-width: 768px) {
    min-width: 50vw;
  }
}

.right-section-tempTwo {
  display: flex;
  flex-direction: column;
  background-color: var(--color-secondary-tempTwo);
  width: auto;
  padding: 0 5px;
  gap: 5px;
}

.contact-item-tempTwo {
  display: flex;
  gap: 2px;
  padding: 2px 2px 2px 0;
  align-items: center;
}
.contact-item-tempTwo span {
  font-size: 0.7rem;
}
